import axios from 'axios';
import User from '../interfaces/User';
import { RadioDataWithPercentile } from '../models/response/RadioDataWithPercentile';

export class BackendService {

  static baseUrl: string = '//megaweb.inoma.it:5555'; // TODO: change up for final version

  static async login(email: string, password: string) {
    let res = await axios.post(
      this.baseUrl + '/login',
      { email, password }
    );

    return res.data;
  }

  static async refreshToken() {
    let res = await axios.post(
      this.baseUrl + '/refresh',
      {}
    );

    return res.data;
  }

  static async getRadioData(days: number): Promise<RadioDataWithPercentile[]> {
    let res;

    if(days === 0) {
      res = await axios.get(
        this.baseUrl + '/get_radio_data_percentile'
      );
    } else {
      res = await axios.get(
        this.baseUrl + '/get_radio_data_percentile/' + days
      );
    }

    return res.data;
  }

  static async getRadioDataWithPercentile(): Promise<RadioDataWithPercentile[]> {
    let res = await axios.get(
      this.baseUrl + '/get_radio_data_percentile'
    );

    return res.data;
  }

  static async getRadioDataWithPercentileCSV(): Promise<string> {
    let res = await axios.get(
      this.baseUrl + '/get_radio_data_percentile/csv'
    );

    return res.data;
  }
}


// AXIOS interceptors config
axios.interceptors.request.use(
  config => {
    if (config === undefined) {
      return config;
    }

    // console.log(config)

    let userJson = sessionStorage.getItem('user');
    if (userJson === null) {
      return config;
    }

    let user: User | null = JSON.parse(userJson);
    if (config.headers !== undefined) {
      if(config.url?.endsWith("refresh")) {
        config.headers['Authorization'] = `Bearer ${user?.refresh_token}`;
      } else {
        config.headers['Authorization'] = `Bearer ${user?.token}`;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    // console.error(error)
    if (error.response.status === 403 && error.response.data.message === "expired_token") {

      if (error.request.responseURL.endsWith("/refresh")) {
        sessionStorage.clear();
        window.location.href = '/';
      }

      let userJson = sessionStorage.getItem('user');
      if (userJson !== null) {
        let user: User | null = JSON.parse(userJson);
        if (user !== null) {
          let res = await BackendService.refreshToken();
          let newAccessToken = res.token;

          user.token = newAccessToken;
          sessionStorage.setItem("user", JSON.stringify(user));

          return Promise.resolve();
        }
      }
    }

    sessionStorage.clear();
    window.location.href = '/';

    return Promise.reject(error);
  }
);
