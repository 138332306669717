import React from 'react';
import useAuth from '../../hooks/useAuth.ts';
import Login from './Login';

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {

  const [user, setUser] = useAuth();

  if(!user) {
    return <Login setUser={setUser} />
  }

  return <>{props.children}</>;
}

export default ProtectedRoute;
