import React from 'react';
import './App.css';
import theme from './theme/default';

import BusyMap from './components/BusyMap';
import TopBar from './components/TopBar';
import { ThemeProvider } from '@mui/material';

import { Routes, Route } from "react-router-dom";
import Login from './components/auth/Login';
import useAuth from './hooks/useAuth.ts';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Paths from './interfaces/Paths';
import StatusTable from './components/StatusTable';

function App() {

  const [setUser] = useAuth();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={Paths.STATUS_TABLE} element={
            <ProtectedRoute>
              <TopBar />
              <StatusTable />
            </ProtectedRoute>
          } />
          <Route path={Paths.MONITORAGGIO} element={
            <ProtectedRoute>
              <TopBar />
              <BusyMap />
            </ProtectedRoute>
          } />
          <Route path={Paths.LOGIN} element={<Login setUser={setUser} />} />
          <Route path={Paths.LOGOUT} element={
            <ProtectedRoute>
              <></>
            </ProtectedRoute>
          } />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
