import React, { useEffect, useState } from "react";
import { RadioDataWithPercentile } from "../models/response/RadioDataWithPercentile";
import { BackendService } from '../services/BackendService';
import Loader from './shared/Loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AerialStatus from "../interfaces/AerialStatus";
import { Fab } from "@mui/material";
import TableViewIcon from '@mui/icons-material/TableView';

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const StatusTable = () => {

  const [data, setData] = useState<RadioDataWithPercentile[]>();
  const [loading, setLoading] = useState<boolean>(false);

  async function fetchData() {
    setLoading(true);

    try {
      let res = await BackendService.getRadioDataWithPercentile();
      setData(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function downloadCsv() {
    setLoading(true);

    try {
      let res = await BackendService.getRadioDataWithPercentileCSV();
      console.log(res);

      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([res]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `antenne.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);
      link.click();

      if (link.parentNode === null) {
        return;
      }

      link.parentNode.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const aerialStatus = (rd: RadioDataWithPercentile) => {
    if (rd.sat_perc_tot_99_perc >= 80) return AerialStatus.RED;
    else if (rd.sat_perc_tot_99_perc > 60 && rd.sat_perc_tot_99_perc < 80) return AerialStatus.YELLOW;
    else return AerialStatus.GREEN;
  }

  const computeRowColorFromStatus = (rdp: RadioDataWithPercentile) => {
    switch (aerialStatus(rdp)) {
      case AerialStatus.RED:
        //return '#D62828';
        return '#ff8080';
      case AerialStatus.YELLOW:
        //return '#FFC800';
        return '#ffe380';
      case AerialStatus.GREEN:
        //return '#4BD28F';
        return '#99e6bf';
    }
  }

  return <>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '100vh' }}>
        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="dati antenne">
          <TableHead>
            <TableRow>
              <TableCell>Radio</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell sx={{ width: '17%' }}>Descrizione</TableCell>
              {/* <TableCell>Posizione (lat, long)</TableCell> */}
              {/* <TableCell>% saturazione</TableCell> */}
              <TableCell>Saturazione (99 perc)</TableCell>
              {/* <TableCell>Picco totale in/out (Mbit/s)</TableCell> */}
              <TableCell>Picco totale (99 perc)</TableCell>
              <TableCell>Capacita' totale</TableCell>
              <TableCell>Picco input</TableCell>
              <TableCell>Data picco input</TableCell>
              <TableCell>Picco output</TableCell>
              <TableCell>Data picco output</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.radio_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: computeRowColorFromStatus(row) }}
              >
                <TableCell>{row.radio_id}</TableCell>
                <TableCell>{row.radio_type}</TableCell>
                <TableCell sx={{ width: '17%' }}>{row.radio_description}</TableCell>
                {/* <TableCell>{row.lat}, {row.long}</TableCell> */}
                {/* <TableCell>{row.sat_perc_tot_max}</TableCell> */}
                <TableCell>{row.sat_perc_tot_99_perc} %</TableCell>
                {/* <TableCell>{row.mbits_total_max}</TableCell> */}
                <TableCell>{row.mbits_total_99_perc} Mbit/s</TableCell>
                <TableCell>{row.mbits_total_capacity_max} Mbit/s</TableCell>
                <TableCell>{row.mbits_in_max} Mbit/s</TableCell>
                <TableCell>{row.timestamp_utc_mbitsin_max}</TableCell>
                <TableCell>{row.mbits_out_max} Mbit/s</TableCell>
                <TableCell>{row.timestamp_utc_mbitsout_max}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>

    <Fab variant="extended" size="medium" sx={fabStyle} color={'secondary'} onClick={downloadCsv}>
      <TableViewIcon />
      CSV
    </Fab>

    <Loader open={loading} />
  </>
}

export default StatusTable;
