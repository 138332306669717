import L from 'leaflet';
import React, { useEffect, useState } from "react";
import * as ReactDOMServer from 'react-dom/server';
import { MapContainer, Marker, Tooltip, TileLayer } from "react-leaflet";
import { BackendService } from '../services/BackendService';
import AerialDetails from "./AerialDetails";
import MarkerClusterGroup from 'react-leaflet-cluster';
import AerialStatus from '../interfaces/AerialStatus';
import Loader from './shared/Loader';
import AerialMarker from './map/AerialMarker';
import TimeFrameSelector from './map/TimeFrameSelector';
import { RadioDataWithPercentile } from '../models/response/RadioDataWithPercentile';

const BusyMap = () => {

  const [radioDataGroups, setRadioDataGroups] = useState<Map<string, RadioDataWithPercentile[]>>();

  const [details, setDetails] = useState<RadioDataWithPercentile>();
  const [detailsStatus, setDetailsStatus] = useState<AerialStatus>();

  const [loading, setLoading] = useState<boolean>(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  async function fetchData(days: number) {

    setLoading(true);

    try {
      let res = await BackendService.getRadioData(days);
      // console.log(res);

      // build map grouped by lat / long
      let groupedData: Map<string, RadioDataWithPercentile[]> = new Map<string, RadioDataWithPercentile[]>();
      res.forEach(function (rd: RadioDataWithPercentile) {
        let key: string = rd.lat + "]]" + rd.long;

        if (!groupedData.has(key)) {
          groupedData.set(key, [rd]);
        } else {
          let tempRd = groupedData.get(key);
          if (tempRd !== undefined) tempRd.push(rd);
          else tempRd = []
          groupedData.set(key, tempRd);
        }
      });

      setRadioDataGroups(groupedData);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(0); // purposefully invalid time frame as default
  }, []);

  const clusterStatus = (rds: RadioDataWithPercentile[]) => {

    let result: AerialStatus = AerialStatus.GREEN;
    let notGoodCount: number = 0;

    rds.forEach(rd => {
      if (aerialStatus(rd) === AerialStatus.RED) {
        result = AerialStatus.YELLOW;
        notGoodCount++;
      }
    });

    return (notGoodCount === rds.length) ? AerialStatus.RED : result;
  }

  const aerialStatus = (rd: RadioDataWithPercentile) => {
    if (rd.sat_perc_tot_99_perc >= 80) return AerialStatus.RED;
    else if (rd.sat_perc_tot_99_perc > 60 && rd.sat_perc_tot_99_perc < 80) return AerialStatus.YELLOW; 
    else return AerialStatus.GREEN;
  }

  const indicatorSize: any = [20, 20];

  const createClusterCustomIconGreen = function (cluster: any) {
    return L.divIcon({
      html: ReactDOMServer.renderToString(<span className="cluster-span">{cluster.getChildCount()}</span>),
      className: 'custom-marker-cluster custom-marker-cluster-green',
      iconSize: L.point(20, 20, true),
    })
  }

  const createClusterCustomIconYellow = function (cluster: any) {
    return L.divIcon({
      html: ReactDOMServer.renderToString(<span className="cluster-span">{cluster.getChildCount()}</span>),
      className: 'custom-marker-cluster custom-marker-cluster-yellow',
      iconSize: L.point(20, 20, true),
    })
  }

  const createClusterCustomIconRed = function (cluster: any) {
    return L.divIcon({
      html: ReactDOMServer.renderToString(<span className="cluster-span">{cluster.getChildCount()}</span>),
      className: 'custom-marker-cluster custom-marker-cluster-red',
      iconSize: L.point(20, 20, true),
    })
  }

  const iconFromStatus = (rd: RadioDataWithPercentile, status: AerialStatus) => {
    return L.divIcon({
      className: 'custom-icon',
      html: ReactDOMServer.renderToString(
        <AerialMarker status={status} type={rd.radio_type} />
      ),
      iconSize: indicatorSize,
    });
  }

  const handleTimeFrameChange = async (index: number) => {
    await fetchData(index);
  }

  return (
    <>
      <MapContainer center={[45.5915, 8.19613]} zoom={11} scrollWheelZoom={true} style={{ minHeight: "92vh" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {
          (radioDataGroups !== undefined)
            ? Array.from(radioDataGroups).map((entry: [string, RadioDataWithPercentile[]]) => {
              let rds: RadioDataWithPercentile[] = entry[1];
              let result: AerialStatus = clusterStatus(rds);

              return <MarkerClusterGroup
                key={entry[0]}
                iconCreateFunction={
                  (result === AerialStatus.GREEN)
                    ? createClusterCustomIconGreen
                    : ((result === AerialStatus.RED)
                      ? createClusterCustomIconRed
                      : createClusterCustomIconYellow)
                }
                spiderfyDistanceMultiplier={2}
              >
                {rds.map((rd: RadioDataWithPercentile) => {
                  let status: AerialStatus = aerialStatus(rd);
                  return <Marker
                    key={rd.radio_id}
                    eventHandlers={{
                      click: () => {
                        setOpen(true)
                        setDetails(rd);
                        setDetailsStatus(status);
                      }
                    }}
                    position={(rd.lat !== null) ? [rd.lat, rd.long] : [1, 1]}
                    icon={iconFromStatus(rd, status)}
                  >
                    <Tooltip>{rd.radio_description}</Tooltip>
                  </Marker>
                })}
              </MarkerClusterGroup>
            })
            : <></>
        }
      </MapContainer>

      <TimeFrameSelector onChange={(i) => handleTimeFrameChange(i)} />

      <AerialDetails
        opened={open}
        handleClose={handleClose}
        data={details}
        status={detailsStatus}
      />

      <Loader open={loading} />
    </>
  );
}

export default BusyMap;
