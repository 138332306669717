import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Beacon from "react-status-beacon";
import AerialStatus from '../interfaces/AerialStatus';
import { RadioDataWithPercentile } from '../models/response/RadioDataWithPercentile';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ComponentProps {
  opened: boolean,
  handleClose: () => void,
  data: RadioDataWithPercentile | undefined
  status: AerialStatus | undefined
}

const defaultPlaceholder: string = "N/A";

const AerialDetails = (Props: ComponentProps) => {

  const getStatusIcon = (status: AerialStatus | undefined) => {
    if(status === AerialStatus.GREEN) {
      return <Beacon status='positive' colors={{ positive: "#4BD28F" }} />
    }

    if(status === AerialStatus.YELLOW) {
      return <Beacon status='neutral' />
    }

    if(status === AerialStatus.RED) {
      return <Beacon status='negative' />
    }
  } 

  return (
    <div>
      <Modal
        open={Props.opened}
        onClose={Props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>
            {Props.data?.radio_description ?? defaultPlaceholder} 
            {getStatusIcon(Props.status)}
          </h2>

          <p>Tipo radio: {Props.data?.radio_type ?? defaultPlaceholder}</p>
          <p>Identificatore radio: {Props.data?.radio_id ?? defaultPlaceholder}</p>
          <p>Posizione (lat, long): {Props.data?.lat ?? defaultPlaceholder} , {Props.data?.long ?? defaultPlaceholder}</p>
          <p>Percentuale saturazione 99 perc: {Props.data?.sat_perc_tot_99_perc ?? defaultPlaceholder} %</p>
          <p>Picco totale in/out 99 perc (Mbit/s): {Props.data?.mbits_total_99_perc ?? defaultPlaceholder}</p>
          <p>Capacita' totale in/out (Mbit/s): {Props.data?.mbits_total_capacity_max ?? defaultPlaceholder}</p>

          <h4>Picco Input (Mbit/s)</h4>
          <p>{Props.data?.mbits_in_max ?? defaultPlaceholder}</p>

          {/*
          <h4>Picco Input (Mb)</h4>
          <p>{Props.data?.sat_perc_in ?? defaultPlaceholder}</p>
          */}

          <h4>Timestamp picco Input</h4>
          <p>{Props.data?.timestamp_utc_mbitsin_max ?? defaultPlaceholder}</p>

          <h4>Picco Output (Mbit/s)</h4>
          <p>{Props.data?.mbits_out_max ?? defaultPlaceholder}</p>
          
          {/*
          <h4>Picco Output (Mb)</h4>
          <p>{Props.data?.sat_perc_out ?? defaultPlaceholder}</p>
          */}

          <h4>Timestamp picco Output</h4>
          <p>{Props.data?.timestamp_utc_mbitsout_max ?? defaultPlaceholder}</p>

          {/* TODO: maybe to complete */}

          <Button onClick={Props.handleClose}>Chiudi</Button>
        </Box>
      </Modal>
    </div>
  );
}

export default AerialDetails;
