import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';

import { Link, useLocation } from "react-router-dom";

import Logo from '../megaweb-logo.png';
import useAuth from '../hooks/useAuth.ts';
import { PageDetail } from '../interfaces/PageDetail';
import Paths from '../interfaces/Paths';

const pages: PageDetail[] = [
  { display: 'ELENCO ANTENNE', link: Paths.STATUS_TABLE },
  { display: 'MAPPA ANTENNE', link: Paths.MONITORAGGIO }
];

const TopBar = () => {

  const [user] = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const { pathname } = useLocation();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload();
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, mr: 1, mb: 1, mt: 1 }}>
            <img
              src={Logo}
              alt={'Megaweb'}
              loading="lazy"
              width={200}
              height={56}
            />
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(pageDetail => {
              return <MenuItem key={pageDetail.display} onClick={handleCloseNavMenu} component={Link} to={pageDetail.link}>
                <Typography className={pageDetail.link === pathname ? 'selected-page' : 'unselected-page'} textAlign="center" sx={{ my: 2, display: 'block', textDecoration: 'none' }}>
                  {pageDetail.display}
                </Typography>
              </MenuItem>
            })}
            <MenuItem key={'logout'} onClick={handleLogout}>
              <Typography textAlign="center" sx={{ my: 2, color: 'white', display: 'block', textDecoration: 'none' }}>
                {user.name + ", LOGOUT"}
              </Typography>
            </MenuItem>
          </Box>

          {/* Responsive Layout */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(pageDetail => {
                return <MenuItem key={pageDetail.display} onClick={handleCloseNavMenu} component={Link} to={pageDetail.link}>
                  <Typography className={pageDetail.link === pathname ? 'selected-page' : ''} textAlign="center" sx={{ my: 2, display: 'block', textDecoration: 'none' }}>
                    {pageDetail.display}
                  </Typography>
                </MenuItem>
              })}
              <MenuItem key={'logout'} onClick={handleLogout}>
                <Typography textAlign="center" sx={{ my: 2, display: 'block', textDecoration: 'none' }}>
                  {user.name + ", LOGOUT"}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
