import { Card, CardContent, Typography, Box, StepButton, Step, Stepper, StepLabel, Chip, StepConnector } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';

interface TimeFrameSelectorProps {
  onChange: (selected: number) => void;
}

const TimeFrameSelector = (props: TimeFrameSelectorProps) => {

  const [selectedTimeFrame, setSelectedTimeFrame] = useState<number>(3);

  const labelStyle = { display: { xs: 'none', md: 'flex' } }

  const getDateLabel = (days: number) => {
    let date = new Date();
    date.setDate(date.getDate() - days);

    return date.toLocaleDateString();
  }

  const secondaryColorIfSelected = (selectedIndex: number) => {
    return (selectedTimeFrame === selectedIndex) ? "secondary" : "primary";
  }

  const handleChange = (index: number, timeframe: number) => {
    setSelectedTimeFrame(index);
    props.onChange(timeframe);
  }

  return (
    <Card sx={{
      minWidth: "35%",
      maxHeight: "10%",
      position: 'absolute',
      top: "94%",
      left: "50%",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      zIndex: 1001,
    }}>
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <Stepper nonLinear activeStep={selectedTimeFrame} alternativeLabel connector={<StepConnector sx={{ ml: 4, mr: 4 }} />}>
            <Step key={0} completed={false}>
              <StepButton icon={<Chip color={secondaryColorIfSelected(0)} label={getDateLabel(7)} />} color="secondary" onClick={() => handleChange(0, 7)}>
                <StepLabel>
                  <Typography sx={labelStyle}>
                    Ultimi 7 giorni
                  </Typography>
                </StepLabel>
              </StepButton>
            </Step>
            <Step key={1} completed={false}>
              <StepButton icon={<Chip color={secondaryColorIfSelected(1)} label={getDateLabel(3)} />} color="secondary" onClick={() => handleChange(1, 3)}>
                <StepLabel>
                  <Typography sx={labelStyle}>
                    Ultimi 3 giorni
                  </Typography>
                </StepLabel>
              </StepButton>
            </Step>
            <Step key={2} completed={false}>
              <StepButton icon={<Chip color={secondaryColorIfSelected(2)} label={getDateLabel(1)} />} color="secondary" onClick={() => handleChange(2, 1)}>
                <StepLabel>
                  <Typography sx={labelStyle}>
                    Ultimo giorno
                  </Typography>
                </StepLabel>
              </StepButton>
            </Step>
            <Step key={3} completed={false}>
              <StepButton icon={<Chip color={secondaryColorIfSelected(3)} label={getDateLabel(0)} />} color="secondary" onClick={() => handleChange(3, 0)}>
                <StepLabel>
                  <Typography sx={labelStyle}>
                    Dati Completi
                  </Typography>
                </StepLabel>
              </StepButton>
            </Step>
          </Stepper>
        </Box>
      </CardContent>
    </Card>
  );
}

export default TimeFrameSelector;
