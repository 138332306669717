import { useState } from 'react';
import User from '../interfaces/User';

const useAuth = (): [User | null, (user: User | null) => void] => {

  const getUser = (): User | null => {
    const user: string | null = sessionStorage.getItem('user');

    if(user !== null && user !== "") {
      return JSON.parse(user);
    }
    
    return null;
  };

  const [user, setUser] = useState<User | null>(getUser());

  const saveUser = (user: User | null): void => {
    sessionStorage.setItem('user', JSON.stringify(user));
    setUser(user);
  };

  return [
    user,
    saveUser
  ]
};

export default useAuth;
