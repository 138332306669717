import React from "react";
import AerialStatus from "../../interfaces/AerialStatus";
import Beacon from "react-status-beacon";

interface AerialMarkerProps {
  status: AerialStatus;
  type: string;
}

const AerialMarker = (props: AerialMarkerProps) => {

  const componentFromStatus = (status: AerialStatus) => {

    let lightStatus: string = '';
    let colors = {};

    if (status === AerialStatus.GREEN) {
      lightStatus = 'positive';
      colors={ positive: "#4BD28F" };
    }

    if (status === AerialStatus.YELLOW) {
      lightStatus = 'neutral';
    }

    if (status === AerialStatus.RED) {
      lightStatus = 'negative';
    }

    return <Beacon className="beaconIcon" status={lightStatus} colors={colors}>
      <p className="aerialTypeMarker">{props.type}</p>
    </Beacon>
  }

  return <>{componentFromStatus(props.status)}</>
}

export default AerialMarker;
