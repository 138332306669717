import React, { useState } from 'react';
import User from '../../interfaces/User';
import { BackendService } from '../../services/BackendService';
import jwt_decode from "jwt-decode";
import Loader from '../shared/Loader';
import { Alert, Box, Button, Container, CssBaseline, Grid, Link, Snackbar, TextField, Typography } from '@mui/material';
import Logo from '../../megaweb-logo-login.png';

interface LoginProps {
  setUser: (user: User | null) => void
}

const Login = (props: LoginProps) => {

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const validateInput = () => {

    setEmailError(false);
    setEmailErrorMessage("");
    setPasswordError(false);
    setPasswordErrorMessage("");

    let valid: boolean = true;

    if (email === "") {
      setEmailError(true);
      setEmailErrorMessage("Email richiesta");

      valid = false;
    }

    if (password === "") {
      setPasswordError(true);
      setPasswordErrorMessage("Password richiesta");

      valid = false;
    }

    return valid;
  }

  const handleLogin = async (e: any) => {
    e.preventDefault();

    if (!validateInput())
      return false;

    setLoading(true);

    try {
      const res = await BackendService.login(email, password);
      let decodedJwt: User = jwt_decode(res.token);
      props.setUser({ name: decodedJwt.name, email: decodedJwt.email, token: res.token, refresh_token: res.refresh_token });
    } catch (e) {
      console.error(e);
      setShowErrorMessage(true);
    } finally {
      setLoading(false);
    }
  }

  return <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, mr: 1, mb: 1, mt: 1 }}>
        <img
          src={Logo}
          alt={'Megaweb'}
          loading="lazy"
        />
      </Box>
      <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
        Sistema Monitoraggio Antenne
      </Typography>
      <Box component="form" onSubmit={(event) => handleLogin(event)} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          error={emailError}
          helperText={emailErrorMessage}
          onChange={(v) => setEmail(v.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={passwordError}
          helperText={passwordErrorMessage}
          onChange={(v) => setPassword(v.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          LOGIN
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              info@megaweb.it
            </Link>
          </Grid>

        </Grid>
      </Box>
    </Box>
    <Snackbar
      open={showErrorMessage}
      autoHideDuration={6000}
      onClose={() => setShowErrorMessage(false)}
    >
      <Alert severity="error">Credenziali non valide</Alert>
    </Snackbar>
    <Loader open={loading} />
  </Container>
}

export default Login;
